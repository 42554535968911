export type CountryFlagItemType = {
  code: string
  flag: string
  name: string
  phone: string
  mask?: string | string[]
  priority?: boolean
}

/**
 * @description - Returns list of countries sorted by code
 * @return an array of CountryFlagItemType
 */
// This is a smaller list with only stuff we sell to
const CountryFlagData = [
  { code: "AD", flag: "🇦🇩", name: "Andorra", phone: "376", mask: "###-###" },
  {
    code: "AE",
    flag: "🇦🇪",
    name: "United Arab Emirates",
    phone: "971",
    mask: ["#-###-####", "5#-###-####"],
  },
  {
    code: "AT",
    flag: "🇦🇹",
    name: "Austria",
    phone: "43",

    mask: "(###) ###-####",
  },
  {
    code: "AU",
    flag: "🇦🇺",
    name: "Australia",
    phone: "61",

    mask: "#-####-####",
  },
  {
    code: "BE",
    flag: "🇧🇪",
    name: "Belgium",
    phone: "32",

    mask: "(###) ###-###",
  },
  {
    code: "BG",
    flag: "🇧🇬",
    name: "Bulgaria",
    phone: "359",
    mask: "(###) ###-###",
  },
  { code: "BH", flag: "🇧🇭", name: "Bahrain", phone: "973", mask: "####-####" },
  {
    code: "BN",
    flag: "🇧🇳",
    name: "Brunei Darussalam",
    phone: "673",
    mask: "###-####",
  },
  {
    code: "BR",
    flag: "🇧🇷",
    name: "Brazil",
    phone: "55",
    mask: ["(##) ####-####", "(##) #####-####"],
  },
  {
    code: "CA",
    flag: "🇨🇦",
    name: "Canada",
    phone: "1",

    mask: "(###) ###-####",
  },
  {
    code: "CH",
    flag: "🇨🇭",
    name: "Switzerland",
    phone: "41",

    mask: "##-###-####",
  },
  { code: "CL", flag: "🇨🇱", name: "Chile", phone: "56", mask: "#-####-####" },
  {
    code: "CN",
    flag: "🇨🇳",
    name: "China",
    phone: "86",
    mask: ["(###) ####-###", "(###) ####-####", "##-#####-#####"],
  },
  { code: "CY", flag: "🇨🇾", name: "Cyprus", phone: "357", mask: "##-###-###" },
  {
    code: "CZ",
    flag: "🇨🇿",
    name: "Czech Republic",
    phone: "420",

    mask: "(###) ###-###",
  },
  {
    code: "DE",
    flag: "🇩🇪",
    name: "Germany",
    phone: "49",

    mask: [
      "###-###",
      "(###) ##-##",
      "(###) ##-###",
      "(###) ##-####",
      "(###) ###-####",
      "(####) ###-####",
    ],
  },
  {
    code: "DK",
    flag: "🇩🇰",
    name: "Denmark",
    phone: "45",

    mask: "##-##-##-##",
  },
  {
    code: "EE",
    flag: "🇪🇪",
    name: "Estonia",
    phone: "372",
    mask: ["###-####", "####-####"],
  },
  {
    code: "ES",
    flag: "🇪🇸",
    name: "Spain",
    phone: "34",

    mask: "(###) ###-###",
  },
  {
    code: "FI",
    flag: "🇫🇮",
    name: "Finland",
    phone: "358",

    mask: "(###) ###-##-##",
  },
  {
    code: "FR",
    flag: "🇫🇷",
    name: "France",
    phone: "33",

    mask: "(###) ###-###",
  },
  {
    code: "GB",
    flag: "🇬🇧",
    name: "United Kingdom",
    phone: "44",

    mask: "##-####-####",
  },
  {
    code: "GR",
    flag: "🇬🇷",
    name: "Greece",
    phone: "30",
    mask: "(###) ###-####",
  },
  {
    code: "HK",
    flag: "🇭🇰",
    name: "Hong Kong",
    phone: "852",
    mask: "####-####",
  },
  { code: "HR", flag: "🇭🇷", name: "Croatia", phone: "385", mask: "##-###-###" },
  {
    code: "HU",
    flag: "🇭🇺",
    name: "Hungary",
    phone: "36",
    mask: "(###) ###-###",
  },
  {
    code: "IE",
    flag: "🇮🇪",
    name: "Ireland",
    phone: "353",

    mask: "(###) ###-###",
  },
  {
    code: "IL",
    flag: "🇮🇱",
    name: "Israel",
    phone: "972",
    mask: ["#-###-####", "5#-###-####"],
  },
  {
    code: "IT",
    flag: "🇮🇹",
    name: "Italy",
    phone: "39",

    mask: "(###) ####-###",
  },
  {
    code: "JP",
    flag: "🇯🇵",
    name: "Japan",
    phone: "81",
    mask: ["(###) ###-###", "##-####-####"],
  },
  {
    code: "KR",
    flag: "🇰🇷",
    name: "South Korea",
    phone: "82",
    mask: "##-###-####",
  },
  { code: "KW", flag: "🇰🇼", name: "Kuwait", phone: "965", mask: "####-####" },
  {
    code: "LT",
    flag: "🇱🇹",
    name: "Lithuania",
    phone: "370",
    mask: "(###) ##-###",
  },
  {
    code: "LU",
    flag: "🇱🇺",
    name: "Luxembourg",
    phone: "352",
    mask: "(###) ###-###",
  },
  { code: "LV", flag: "🇱🇻", name: "Latvia", phone: "371", mask: "##-###-###" },
  {
    code: "MC",
    flag: "🇲🇨",
    name: "Monaco",
    phone: "377",
    mask: ["##-###-###", "(###) ###-###"],
  },
  { code: "MO", flag: "🇲🇴", name: "Macao", phone: "853", mask: "####-####" },
  { code: "MT", flag: "🇲🇹", name: "Malta", phone: "356", mask: "####-####" },
  {
    code: "MX",
    flag: "🇲🇽",
    name: "Mexico",
    phone: "52",
    mask: ["##-##-####", "(###) ###-####"],
  },
  {
    code: "MY",
    flag: "🇲🇾",
    name: "Malaysia",
    phone: "60",
    mask: ["#-###-###", "##-###-###", "(###) ###-###", "##-###-####"],
  },
  {
    code: "NL",
    flag: "🇳🇱",
    name: "Netherlands",
    phone: "31",
    mask: "##-###-####",
  },
  {
    code: "NO",
    flag: "🇳🇴",
    name: "Norway",
    phone: "47",

    mask: "(###) ##-###",
  },
  {
    code: "NZ",
    flag: "🇳🇿",
    name: "New Zealand",
    phone: "64",

    mask: ["#-###-###", "(###) ###-###", "(###) ###-####"],
  },
  {
    code: "PH",
    flag: "🇵🇭",
    name: "Philippines",
    phone: "63",
    mask: "(###) ###-####",
  },
  {
    code: "PL",
    flag: "🇵🇱",
    name: "Poland",
    phone: "48",
    mask: "(###) ###-###",
  },
  {
    code: "PT",
    flag: "🇵🇹",
    name: "Portugal",
    phone: "351",
    mask: "##-###-####",
  },
  { code: "QA", flag: "🇶🇦", name: "Qatar", phone: "974", mask: "####-####" },
  { code: "RO", flag: "🇷🇴", name: "Romania", phone: "40", mask: "##-###-####" },
  {
    code: "SA",
    flag: "🇸🇦",
    name: "Saudi Arabia",
    phone: "966",
    mask: ["#-###-####", "5#-####-####"],
  },
  {
    code: "SE",
    flag: "🇸🇪",
    name: "Sweden",
    phone: "46",
    mask: "##-###-####",
  },
  {
    code: "SG",
    flag: "🇸🇬",
    name: "Singapore",
    phone: "65",
    mask: "####-####",
  },
  {
    code: "SI",
    flag: "🇸🇮",
    name: "Slovenia",
    phone: "386",
    mask: "##-###-###",
  },
  {
    code: "SK",
    flag: "🇸🇰",
    name: "Slovakia",
    phone: "421",
    mask: "(###) ###-###",
  },
  {
    code: "SM",
    flag: "🇸🇲",
    name: "San Marino",
    phone: "378",
    mask: "####-######",
  },
  {
    code: "TH",
    flag: "🇹🇭",
    name: "Thailand",
    phone: "66",
    mask: ["##-###-###", "##-###-####"],
  },
  {
    code: "TR",
    flag: "🇹🇷",
    name: "Turkey",
    phone: "90",
    mask: "(###) ###-####",
  },
  {
    code: "TW",
    flag: "🇹🇼",
    name: "Taiwan",
    phone: "886",
    mask: ["####-####", "#-####-####"],
  },
  {
    code: "US",
    flag: "🇺🇸",
    name: "United States",
    phone: "1",
    mask: "(###) ###-####",
  },
  {
    code: "VN",
    flag: "🇻🇳",
    name: "Vietnam",
    phone: "84",
    mask: ["##-####-###", "(###) ####-###"],
  },
]

/*

// TODO: Missing Curaçao and Western Sahara masks
// TODO: Fix spaces after parenthesis and maybe change from # to 9.
// This is the older list with ALL countries
const CountryFlagData: CountryFlagItemType[] = [
  { code: "AD", flag: "🇦🇩", name: "Andorra", phone: "376", mask: "###-###" },
  {
    code: "AE",
    flag: "🇦🇪",
    name: "United Arab Emirates",
    phone: "971",

    mask: ["#-###-####", "5#-###-####"],
  },
  {
    code: "AF",
    flag: "🇦🇫",
    name: "Afghanistan",
    phone: "93",
    mask: "##-###-####",
  },
  {
    code: "AG",
    flag: "🇦🇬",
    name: "Antigua and Barbuda",
    phone: "1-268",
    mask: "(268) ###-####",
  },
  {
    code: "AI",
    flag: "🇦🇮",
    name: "Anguilla",
    phone: "1-264",
    mask: "(264) ###-####",
  },
  {
    code: "AL",
    flag: "🇦🇱",
    name: "Albania",
    phone: "355",
    mask: "(###) ###-###",
  },
  { code: "AM", flag: "🇦🇲", name: "Armenia", phone: "374", mask: "##-###-###" },
  {
    code: "AO",
    flag: "🇦🇴",
    name: "Angola",
    phone: "244",
    mask: "(###) ###-###",
  },
  { code: "AQ", flag: "🇦🇶", name: "Antarctica", phone: "672", mask: "1##-###" },
  {
    code: "AR",
    flag: "🇦🇷",
    name: "Argentina",
    phone: "54",
    mask: "(###) ###-####",
  },
  {
    code: "AS",
    flag: "🇦🇸",
    name: "American Samoa",
    phone: "1-684",
    mask: "(684) ###-####",
  },
  {
    code: "AT",
    flag: "🇦🇹",
    name: "Austria",
    phone: "43",

    mask: "(###) ###-####",
  },
  {
    code: "AU",
    flag: "🇦🇺",
    name: "Australia",
    phone: "61",
    priority: true,
    mask: "#-####-####",
  },
  { code: "AW", flag: "🇦🇼", name: "Aruba", phone: "297", mask: "###-####" },
  {
    code: "AX",
    flag: "🇦🇽",
    name: "Åland Islands",
    phone: "358",
    mask: "(###) ###-##-##",
  },
  {
    code: "AZ",
    flag: "🇦🇿",
    name: "Azerbaijan",
    phone: "994",
    mask: "##-###-##-##",
  },
  {
    code: "BA",
    flag: "🇧🇦",
    name: "Bosnia and Herzegovina",
    phone: "387",
    mask: ["##-####", "##-#####"],
  },
  {
    code: "BB",
    flag: "🇧🇧",
    name: "Barbados",
    phone: "1-246",
    mask: "(246) ###-####",
  },
  {
    code: "BD",
    flag: "🇧🇩",
    name: "Bangladesh",
    phone: "880",
    mask: "1###-######",
  },
  {
    code: "BE",
    flag: "🇧🇪",
    name: "Belgium",
    phone: "32",
    priority: true,
    mask: "(###) ###-###",
  },
  {
    code: "BF",
    flag: "🇧🇫",
    name: "Burkina Faso",
    phone: "226",
    mask: "##-##-####",
  },
  {
    code: "BG",
    flag: "🇧🇬",
    name: "Bulgaria",
    phone: "359",
    mask: "(###) ###-###",
  },
  { code: "BH", flag: "🇧🇭", name: "Bahrain", phone: "973", mask: "####-####" },
  { code: "BI", flag: "🇧🇮", name: "Burundi", phone: "257", mask: "##-##-####" },
  { code: "BJ", flag: "🇧🇯", name: "Benin", phone: "229", mask: "##-##-####" },
  {
    code: "BL",
    flag: "🇧🇱",
    name: "Saint Barthélemy",
    phone: "590",
    mask: "###-##-##-##",
  },
  {
    code: "BM",
    flag: "🇧🇲",
    name: "Bermuda",
    phone: "1-441",
    mask: "(441) ###-####",
  },
  {
    code: "BN",
    flag: "🇧🇳",
    name: "Brunei Darussalam",
    phone: "673",
    mask: "###-####",
  },
  { code: "BO", flag: "🇧🇴", name: "Bolivia", phone: "591", mask: "#-###-####" },
  {
    code: "BR",
    flag: "🇧🇷",
    name: "Brazil",
    phone: "55",
    mask: ["(##) ####-####", "(##) #####-####"],
  },
  {
    code: "BS",
    flag: "🇧🇸",
    name: "Bahamas",
    phone: "1-242",
    mask: "(242) ###-####",
  },
  {
    code: "BT",
    flag: "🇧🇹",
    name: "Bhutan",
    phone: "975",
    mask: ["17-###-###", "77-###-###", "#-###-###"],
  },
  {
    code: "BW",
    flag: "🇧🇼",
    name: "Botswana",
    phone: "267",
    mask: "##-###-###",
  },
  {
    code: "BY",
    flag: "🇧🇾",
    name: "Belarus",
    phone: "375",
    mask: "(##) ###-##-##",
  },
  { code: "BZ", flag: "🇧🇿", name: "Belize", phone: "501", mask: "###-####" },
  {
    code: "CA",
    flag: "🇨🇦",
    name: "Canada",
    phone: "1",
    priority: true,
    mask: "(###) ###-####",
  },
  {
    code: "CC",
    flag: "🇨🇨",
    name: "Cocos Islands",
    phone: "61",
    mask: "#-####-####",
  },
  {
    code: "CD",
    flag: "🇨🇩",
    name: "Congo",
    phone: "243",
    mask: "(###) ###-###",
  },
  {
    code: "CF",
    flag: "🇨🇫",
    name: "Central African Republic",
    phone: "236",
    mask: "##-##-####",
  },
  { code: "CG", flag: "🇨🇬", name: "Congo", phone: "242", mask: "##-#####" },
  {
    code: "CH",
    flag: "🇨🇭",
    name: "Switzerland",
    phone: "41",
    priority: true,
    mask: "##-###-####",
  },
  {
    code: "CI",
    flag: "🇨🇮",
    name: "Côte D'Ivoire",
    phone: "225",
    mask: "##-###-###",
  },
  {
    code: "CK",
    flag: "🇨🇰",
    name: "Cook Islands",
    phone: "682",
    mask: "##-###",
  },
  { code: "CL", flag: "🇨🇱", name: "Chile", phone: "56", mask: "#-####-####" },
  { code: "CM", flag: "🇨🇲", name: "Cameroon", phone: "237", mask: "####-####" },
  {
    code: "CN",
    flag: "🇨🇳",
    name: "China",
    phone: "86",
    mask: ["(###) ####-###", "(###) ####-####", "##-#####-#####"],
  },
  {
    code: "CO",
    flag: "🇨🇴",
    name: "Colombia",
    phone: "57",
    mask: "(###) ###-####",
  },
  {
    code: "CR",
    flag: "🇨🇷",
    name: "Costa Rica",
    phone: "506",
    mask: "####-####",
  },
  {
    code: "CV",
    flag: "🇨🇻",
    name: "Cape Verde",
    phone: "238",
    mask: "(###) ##-##",
  },
  { code: "CW", flag: "🇨🇼", name: "Curaçao", phone: "599" },
  {
    code: "CX",
    flag: "🇨🇽",
    name: "Christmas Island",
    phone: "61",
    mask: "#-####-####",
  },
  { code: "CY", flag: "🇨🇾", name: "Cyprus", phone: "357", mask: "##-###-###" },
  {
    code: "CZ",
    flag: "🇨🇿",
    name: "Czech Republic",
    phone: "420",
    priority: true,
    mask: "(###) ###-###",
  },
  {
    code: "DE",
    flag: "🇩🇪",
    name: "Germany",
    phone: "49",
    priority: true,
    mask: [
      "###-###",
      "(###) ##-##",
      "(###) ##-###",
      "(###) ##-####",
      "(###) ###-####",
      "(####) ###-####",
    ],
  },
  {
    code: "DJ",
    flag: "🇩🇯",
    name: "Djibouti",
    phone: "253",
    mask: "##-##-##-##",
  },
  {
    code: "DK",
    flag: "🇩🇰",
    name: "Denmark",
    phone: "45",
    priority: true,
    mask: "##-##-##-##",
  },
  {
    code: "DM",
    flag: "🇩🇲",
    name: "Dominica",
    phone: "1-767",
    mask: "(767) ###-####",
  },
  {
    code: "DO",
    flag: "🇩🇴",
    name: "Dominican Republic",
    phone: "1",
    mask: ["(809) ###-####", "(829) ###-####", "(849) ###-####"],
  },
  {
    code: "DZ",
    flag: "🇩🇿",
    name: "Algeria",
    phone: "213",
    mask: "##-###-####",
  },
  {
    code: "EC",
    flag: "🇪🇨",
    name: "Ecuador",
    phone: "593",
    mask: ["#-###-####", "##-###-####"],
  },
  {
    code: "EE",
    flag: "🇪🇪",
    name: "Estonia",
    phone: "372",
    mask: ["###-####", "####-####"],
  },
  {
    code: "EG",
    flag: "🇪🇬",
    name: "Egypt",
    phone: "20",
    mask: "(###) ###-####",
  },
  { code: "EH", flag: "🇪🇭", name: "Western Sahara", phone: "212" },
  { code: "ER", flag: "🇪🇷", name: "Eritrea", phone: "291", mask: "#-###-###" },
  {
    code: "ES",
    flag: "🇪🇸",
    name: "Spain",
    phone: "34",
    priority: true,
    mask: "(###) ###-###",
  },
  {
    code: "ET",
    flag: "🇪🇹",
    name: "Ethiopia",
    phone: "251",
    mask: "##-###-####",
  },
  {
    code: "FI",
    flag: "🇫🇮",
    name: "Finland",
    phone: "358",
    priority: true,
    mask: "(###) ###-##-##",
  },
  { code: "FJ", flag: "🇫🇯", name: "Fiji", phone: "679", mask: "##-#####" },
  {
    code: "FK",
    flag: "🇫🇰",
    name: "Falkland Islands",
    phone: "500",
    mask: "#####",
  },
  {
    code: "FM",
    flag: "🇫🇲",
    name: "Micronesia",
    phone: "691",
    mask: "###-####",
  },
  {
    code: "FO",
    flag: "🇫🇴",
    name: "Faroe Islands",
    phone: "298",
    mask: "###-###",
  },
  {
    code: "FR",
    flag: "🇫🇷",
    name: "France",
    phone: "33",
    priority: true,
    mask: "(###) ###-###",
  },
  { code: "GA", flag: "🇬🇦", name: "Gabon", phone: "241", mask: "#-##-##-##" },
  {
    code: "GB",
    flag: "🇬🇧",
    name: "United Kingdom",
    phone: "44",
    priority: true,
    mask: "##-####-####",
  },
  {
    code: "GD",
    flag: "🇬🇩",
    name: "Grenada",
    phone: "1-473",
    mask: "(473) ###-####",
  },
  {
    code: "GE",
    flag: "🇬🇪",
    name: "Georgia",
    phone: "995",
    mask: "(###) ###-###",
  },
  {
    code: "GG",
    flag: "🇬🇬",
    name: "Guernsey",
    phone: "44-1481",
    mask: "(####) ######",
  },
  {
    code: "GH",
    flag: "🇬🇭",
    name: "Ghana",
    phone: "233",
    mask: "(###) ###-###",
  },
  {
    code: "GI",
    flag: "🇬🇮",
    name: "Gibraltar",
    phone: "350",
    mask: "###-#####",
  },
  { code: "GL", flag: "🇬🇱", name: "Greenland", phone: "299", mask: "##-##-##" },
  { code: "GM", flag: "🇬🇲", name: "Gambia", phone: "220", mask: "(###) ##-##" },
  { code: "GN", flag: "🇬🇳", name: "Guinea", phone: "224", mask: "##-###-###" },
  {
    code: "GQ",
    flag: "🇬🇶",
    name: "Equatorial Guinea",
    phone: "240",
    mask: "##-###-####",
  },
  {
    code: "GR",
    flag: "🇬🇷",
    name: "Greece",
    phone: "30",
    mask: "(###) ###-####",
  },
  {
    code: "GT",
    flag: "🇬🇹",
    name: "Guatemala",
    phone: "502",
    mask: "#-###-####",
  },
  {
    code: "GU",
    flag: "🇬🇺",
    name: "Guam",
    phone: "1-671",
    mask: "(671) ###-####",
  },
  {
    code: "GW",
    flag: "🇬🇼",
    name: "Guinea-Bissau",
    phone: "245",
    mask: "#-######",
  },
  { code: "GY", flag: "🇬🇾", name: "Guyana", phone: "592", mask: "###-####" },
  {
    code: "HK",
    flag: "🇭🇰",
    name: "Hong Kong",
    phone: "852",
    mask: "####-####",
  },
  { code: "HN", flag: "🇭🇳", name: "Honduras", phone: "504", mask: "####-####" },
  { code: "HR", flag: "🇭🇷", name: "Croatia", phone: "385", mask: "##-###-###" },
  { code: "HT", flag: "🇭🇹", name: "Haiti", phone: "509", mask: "##-##-####" },
  {
    code: "HU",
    flag: "🇭🇺",
    name: "Hungary",
    phone: "36",
    mask: "(###) ###-###",
  },
  {
    code: "ID",
    flag: "🇮🇩",
    name: "Indonesia",
    phone: "62",
    mask: [
      "##-###-##",
      "##-###-###",
      "##-###-####",
      "(8##) ###-###",
      "(8##) ###-##-###",
    ],
  },
  {
    code: "IE",
    flag: "🇮🇪",
    name: "Ireland",
    phone: "353",
    priority: true,
    mask: "(###) ###-###",
  },
  {
    code: "IL",
    flag: "🇮🇱",
    name: "Israel",
    phone: "972",
    mask: ["#-###-####", "5#-###-####"],
  },
  {
    code: "IM",
    flag: "🇮🇲",
    name: "Isle of Man",
    phone: "44-1624",
    mask: "(####) ######",
  },
  {
    code: "IN",
    flag: "🇮🇳",
    name: "India",
    phone: "91",
    mask: "(####) ###-###",
  },
  {
    code: "IO",
    flag: "🇮🇴",
    name: "British Indian Ocean Territory",
    phone: "246",
    mask: "###-####",
  },
  {
    code: "IQ",
    flag: "🇮🇶",
    name: "Iraq",
    phone: "964",
    mask: "(###) ###-####",
  },
  { code: "IS", flag: "🇮🇸", name: "Iceland", phone: "354", mask: "###-####" },
  {
    code: "IT",
    flag: "🇮🇹",
    name: "Italy",
    phone: "39",
    priority: true,
    mask: "(###) ####-###",
  },
  {
    code: "JE",
    flag: "🇯🇪",
    name: "Jersey",
    phone: "44-1534",
    mask: "(####) ####-######",
  },
  {
    code: "JM",
    flag: "🇯🇲",
    name: "Jamaica",
    phone: "1-876",
    mask: "(876) ###-####",
  },
  { code: "JO", flag: "🇯🇴", name: "Jordan", phone: "962", mask: "#-####-####" },
  {
    code: "JP",
    flag: "🇯🇵",
    name: "Japan",
    phone: "81",
    mask: ["(###) ###-###", "##-####-####"],
  },
  { code: "KE", flag: "🇰🇪", name: "Kenya", phone: "254", mask: "###-######" },
  {
    code: "KG",
    flag: "🇰🇬",
    name: "Kyrgyzstan",
    phone: "996",
    mask: "(###) ###-###",
  },
  {
    code: "KH",
    flag: "🇰🇭",
    name: "Cambodia",
    phone: "855",
    mask: "##-###-###",
  },
  { code: "KI", flag: "🇰🇮", name: "Kiribati", phone: "686", mask: "##-###" },
  { code: "KM", flag: "🇰🇲", name: "Comoros", phone: "269", mask: "##-#####" },
  {
    code: "KN",
    flag: "🇰🇳",
    name: "Saint Kitts and Nevis",
    phone: "1-869",
    mask: "(869) ###-####",
  },
  {
    code: "KR",
    flag: "🇰🇷",
    name: "South Korea",
    phone: "82",
    mask: "##-###-####",
  },
  { code: "KW", flag: "🇰🇼", name: "Kuwait", phone: "965", mask: "####-####" },
  {
    code: "KY",
    flag: "🇰🇾",
    name: "Cayman Islands",
    phone: "1-345",
    mask: "(345) ###-####",
  },
  {
    code: "KZ",
    flag: "🇰🇿",
    name: "Kazakhstan",
    phone: "7",
    mask: ["(6##) ###-##-##", "(7##) ###-##-##"],
  },
  {
    code: "LA",
    flag: "🇱🇦",
    name: "Laos",
    phone: "856",
    mask: ["##-###-###", "(20##) ###-###"],
  },
  {
    code: "LB",
    flag: "🇱🇧",
    name: "Lebanon",
    phone: "961",
    mask: ["#-###-###", "##-###-###"],
  },
  {
    code: "LC",
    flag: "🇱🇨",
    name: "Saint Lucia",
    phone: "1-758",
    mask: "(758) ###-####",
  },
  {
    code: "LI",
    flag: "🇱🇮",
    name: "Liechtenstein",
    phone: "423",
    mask: "(###) ###-####",
  },
  {
    code: "LK",
    flag: "🇱🇰",
    name: "Sri Lanka",
    phone: "94",
    mask: "##-###-####",
  },
  { code: "LR", flag: "🇱🇷", name: "Liberia", phone: "231", mask: "##-###-###" },
  { code: "LS", flag: "🇱🇸", name: "Lesotho", phone: "266", mask: "#-###-####" },
  {
    code: "LT",
    flag: "🇱🇹",
    name: "Lithuania",
    phone: "370",
    mask: "(###) ##-###",
  },
  {
    code: "LU",
    flag: "🇱🇺",
    name: "Luxembourg",
    phone: "352",
    mask: "(###) ###-###",
  },
  { code: "LV", flag: "🇱🇻", name: "Latvia", phone: "371", mask: "##-###-###" },
  {
    code: "LY",
    flag: "🇱🇾",
    name: "Libya",
    phone: "218",
    mask: ["##-###-###", "21-###-####"],
  },
  {
    code: "MA",
    flag: "🇲🇦",
    name: "Morocco",
    phone: "212",
    mask: "##-####-###",
  },
  {
    code: "MC",
    flag: "🇲🇨",
    name: "Monaco",
    phone: "377",
    mask: ["##-###-###", "(###) ###-###"],
  },
  { code: "MD", flag: "🇲🇩", name: "Moldova", phone: "373", mask: "####-####" },
  {
    code: "ME",
    flag: "🇲🇪",
    name: "Montenegro",
    phone: "382",
    mask: "##-###-###",
  },
  {
    code: "MF",
    flag: "🇲🇫",
    name: "Saint Martin",
    phone: "590",
    mask: "(###) ###-###",
  },
  {
    code: "MG",
    flag: "🇲🇬",
    name: "Madagascar",
    phone: "261",
    mask: "##-##-#####",
  },
  {
    code: "MH",
    flag: "🇲🇭",
    name: "Marshall Islands",
    phone: "692",
    mask: "###-####",
  },
  {
    code: "MK",
    flag: "🇲🇰",
    name: "North Macedonia",
    phone: "389",
    mask: "##-###-###",
  },
  { code: "ML", flag: "🇲🇱", name: "Mali", phone: "223", mask: "##-##-####" },
  {
    code: "MM",
    flag: "🇲🇲",
    name: "Myanmar",
    phone: "95",
    mask: ["###-###", "#-###-###", "##-###-###"],
  },
  {
    code: "MN",
    flag: "🇲🇳",
    name: "Mongolia",
    phone: "976",
    mask: "##-##-####",
  },
  { code: "MO", flag: "🇲🇴", name: "Macao", phone: "853", mask: "####-####" },
  {
    code: "MP",
    flag: "🇲🇵",
    name: "Northern Mariana Islands",
    phone: "1-670",
    mask: "(670) ###-####",
  },
  {
    code: "MR",
    flag: "🇲🇷",
    name: "Mauritania",
    phone: "222",
    mask: "##-##-####",
  },
  {
    code: "MS",
    flag: "🇲🇸",
    name: "Montserrat",
    phone: "1-664",
    mask: "(664) ###-####",
  },
  { code: "MT", flag: "🇲🇹", name: "Malta", phone: "356", mask: "####-####" },
  { code: "MU", flag: "🇲🇺", name: "Mauritius", phone: "230", mask: "###-####" },
  { code: "MV", flag: "🇲🇻", name: "Maldives", phone: "960", mask: "###-####" },
  {
    code: "MW",
    flag: "🇲🇼",
    name: "Malawi",
    phone: "265",
    mask: ["1-###-###", "#-####-####"],
  },
  {
    code: "MX",
    flag: "🇲🇽",
    name: "Mexico",
    phone: "52",
    mask: ["##-##-####", "(###) ###-####"],
  },
  {
    code: "MY",
    flag: "🇲🇾",
    name: "Malaysia",
    phone: "60",
    mask: ["#-###-###", "##-###-###", "(###) ###-###", "##-###-####"],
  },
  {
    code: "MZ",
    flag: "🇲🇿",
    name: "Mozambique",
    phone: "258",
    mask: "##-###-###",
  },
  {
    code: "NA",
    flag: "🇳🇦",
    name: "Namibia",
    phone: "264",
    mask: "##-###-####",
  },
  {
    code: "NC",
    flag: "🇳🇨",
    name: "New Caledonia",
    phone: "687",
    mask: "##-####",
  },
  { code: "NE", flag: "🇳🇪", name: "Niger", phone: "227", mask: "##-##-####" },
  {
    code: "NG",
    flag: "🇳🇬",
    name: "Nigeria",
    phone: "234",
    mask: ["##-###-##", "##-###-###", "(###) ###-####"],
  },
  {
    code: "NI",
    flag: "🇳🇮",
    name: "Nicaragua",
    phone: "505",
    mask: "####-####",
  },
  {
    code: "NL",
    flag: "🇳🇱",
    name: "Netherlands",
    phone: "31",
    priority: true,
    mask: "##-###-####",
  },
  {
    code: "NO",
    flag: "🇳🇴",
    name: "Norway",
    phone: "47",
    priority: true,
    mask: "(###) ##-###",
  },
  { code: "NP", flag: "🇳🇵", name: "Nepal", phone: "977", mask: "##-###-###" },
  { code: "NR", flag: "🇳🇷", name: "Nauru", phone: "674", mask: "###-####" },
  { code: "NU", flag: "🇳🇺", name: "Niue", phone: "683", mask: "####" },
  {
    code: "NZ",
    flag: "🇳🇿",
    name: "New Zealand",
    phone: "64",
    priority: true,
    mask: ["#-###-###", "(###) ###-###", "(###) ###-####"],
  },
  { code: "OM", flag: "🇴🇲", name: "Oman", phone: "968", mask: "##-###-###" },
  { code: "PA", flag: "🇵🇦", name: "Panama", phone: "507", mask: "###-####" },
  { code: "PE", flag: "🇵🇪", name: "Peru", phone: "51", mask: "(###) ###-###" },
  {
    code: "PF",
    flag: "🇵🇫",
    name: "French Polynesia",
    phone: "689",
    mask: "##-##-##",
  },
  {
    code: "PG",
    flag: "🇵🇬",
    name: "Papua New Guinea",
    phone: "675",
    mask: "(###) ##-###",
  },
  {
    code: "PH",
    flag: "🇵🇭",
    name: "Philippines",
    phone: "63",
    mask: "(###) ###-####",
  },
  {
    code: "PK",
    flag: "🇵🇰",
    name: "Pakistan",
    phone: "92",
    mask: "(###) ###-####",
  },
  {
    code: "PL",
    flag: "🇵🇱",
    name: "Poland",
    phone: "48",
    mask: "(###) ###-###",
  },
  {
    code: "PM",
    flag: "🇵🇲",
    name: "Saint Pierre and Miquelon",
    phone: "508",
    mask: "##-####",
  },
  {
    code: "PN",
    flag: "🇵🇳",
    name: "Pitcairn",
    phone: "64",
    mask: "###-###-###",
  },
  {
    code: "PR",
    flag: "🇵🇷",
    name: "Puerto Rico",
    phone: "1",
    mask: ["(787) ### ####", "(939) ### ####"],
  },
  {
    code: "PS",
    flag: "🇵🇸",
    name: "Palestine",
    phone: "970",
    mask: "##-###-####",
  },
  {
    code: "PT",
    flag: "🇵🇹",
    name: "Portugal",
    phone: "351",
    mask: "##-###-####",
  },
  { code: "PW", flag: "🇵🇼", name: "Palau", phone: "680", mask: "###-####" },
  {
    code: "PY",
    flag: "🇵🇾",
    name: "Paraguay",
    phone: "595",
    mask: "(###) ###-###",
  },
  { code: "QA", flag: "🇶🇦", name: "Qatar", phone: "974", mask: "####-####" },
  { code: "RE", flag: "🇷🇪", name: "Réunion", phone: "262", mask: "#####-####" },
  { code: "RO", flag: "🇷🇴", name: "Romania", phone: "40", mask: "##-###-####" },
  { code: "RS", flag: "🇷🇸", name: "Serbia", phone: "381", mask: "##-###-####" },
  {
    code: "RU",
    flag: "🇷🇺",
    name: "Russia",
    phone: "7",
    mask: "(###) ###-##-##",
  },
  {
    code: "RW",
    flag: "🇷🇼",
    name: "Rwanda",
    phone: "250",
    mask: "(###) ###-###",
  },
  {
    code: "SA",
    flag: "🇸🇦",
    name: "Saudi Arabia",
    phone: "966",
    priority: true,
    mask: ["#-###-####", "5#-####-####"],
  },
  {
    code: "SB",
    flag: "🇸🇧",
    name: "Solomon Islands",
    phone: "677",
    mask: ["#####", "###-####"],
  },
  {
    code: "SC",
    flag: "🇸🇨",
    name: "Seychelles",
    phone: "248",
    mask: "#-###-###",
  },
  { code: "SD", flag: "🇸🇩", name: "Sudan", phone: "249", mask: "##-###-####" },
  {
    code: "SE",
    flag: "🇸🇪",
    name: "Sweden",
    phone: "46",
    priority: true,
    mask: "##-###-####",
  },
  {
    code: "SG",
    flag: "🇸🇬",
    name: "Singapore",
    phone: "65",
    priority: true,
    mask: "####-####",
  },
  { code: "SH", flag: "🇸🇭", name: "Saint Helena", phone: "290", mask: "####" },
  {
    code: "SI",
    flag: "🇸🇮",
    name: "Slovenia",
    phone: "386",
    mask: "##-###-###",
  },
  {
    code: "SJ",
    flag: "🇸🇯",
    name: "Svalbard and Jan Mayen",
    phone: "47",
    mask: "(###) ##-###",
  },
  {
    code: "SK",
    flag: "🇸🇰",
    name: "Slovakia",
    phone: "421",
    mask: "(###) ###-###",
  },
  {
    code: "SL",
    flag: "🇸🇱",
    name: "Sierra Leone",
    phone: "232",
    mask: "##-######",
  },
  {
    code: "SM",
    flag: "🇸🇲",
    name: "San Marino",
    phone: "378",
    mask: "####-######",
  },
  {
    code: "SN",
    flag: "🇸🇳",
    name: "Senegal",
    phone: "221",
    mask: "##-###-####",
  },
  {
    code: "SO",
    flag: "🇸🇴",
    name: "Somalia",
    phone: "252",
    mask: ["#-###-###", "##-###-###"],
  },
  {
    code: "SR",
    flag: "🇸🇷",
    name: "Suriname",
    phone: "597",
    mask: ["###-###", "###-####"],
  },
  {
    code: "SS",
    flag: "🇸🇸",
    name: "South Sudan",
    phone: "211",
    mask: "##-###-####",
  },
  {
    code: "ST",
    flag: "🇸🇹",
    name: "Sao Tome and Principe",
    phone: "239",
    mask: "##-#####",
  },
  {
    code: "SV",
    flag: "🇸🇻",
    name: "El Salvador",
    phone: "503",
    mask: "##-##-####",
  },
  {
    code: "SX",
    flag: "🇸🇽",
    name: "Sint Maarten",
    phone: "1-721",
    mask: "(721) ###-####",
  },
  {
    code: "SY",
    flag: "🇸🇾",
    name: "Syrian Arab Republic",
    phone: "963",
    mask: "##-####-###",
  },
  {
    code: "SZ",
    flag: "🇸🇿",
    name: "Swaziland",
    phone: "268",
    mask: "##-##-####",
  },
  {
    code: "TC",
    flag: "🇹🇨",
    name: "Turks and Caicos Islands",
    phone: "1-649",
    mask: "(249) ###-###",
  },
  { code: "TD", flag: "🇹🇩", name: "Chad", phone: "235", mask: "##-##-##-##" },
  { code: "TG", flag: "🇹🇬", name: "Togo", phone: "228", mask: "##-###-###" },
  {
    code: "TH",
    flag: "🇹🇭",
    name: "Thailand",
    phone: "66",
    mask: ["##-###-###", "##-###-####"],
  },
  {
    code: "TJ",
    flag: "🇹🇯",
    name: "Tajikistan",
    phone: "992",
    mask: "##-###-####",
  },
  { code: "TK", flag: "🇹🇰", name: "Tokelau", phone: "690", mask: "####" },
  {
    code: "TL",
    flag: "🇹🇱",
    name: "Timor-Leste",
    phone: "670",
    mask: ["###-####", "77#-#####", "78#-#####"],
  },
  {
    code: "TM",
    flag: "🇹🇲",
    name: "Turkmenistan",
    phone: "993",
    mask: "#-###-####",
  },
  { code: "TN", flag: "🇹🇳", name: "Tunisia", phone: "216", mask: "##-###-###" },
  { code: "TO", flag: "🇹🇴", name: "Tonga", phone: "676", mask: "#####" },
  {
    code: "TR",
    flag: "🇹🇷",
    name: "Turkey",
    phone: "90",
    mask: "(###) ###-####",
  },
  {
    code: "TT",
    flag: "🇹🇹",
    name: "Trinidad and Tobago",
    phone: "1-868",
    mask: "(868) ###-####",
  },
  {
    code: "TV",
    flag: "🇹🇻",
    name: "Tuvalu",
    phone: "688",
    mask: ["2####", "90####"],
  },
  {
    code: "TW",
    flag: "🇹🇼",
    name: "Taiwan",
    phone: "886",
    mask: ["####-####", "#-####-####"],
  },
  {
    code: "TZ",
    flag: "🇹🇿",
    name: "Tanzania",
    phone: "255",
    mask: "##-###-####",
  },
  {
    code: "UA",
    flag: "🇺🇦",
    name: "Ukraine",
    phone: "380",
    mask: "(##) ###-##-##",
  },
  {
    code: "UG",
    flag: "🇺🇬",
    name: "Uganda",
    phone: "256",
    mask: "(###) ###-###",
  },
  {
    code: "US",
    flag: "🇺🇸",
    name: "United States",
    phone: "1",
    priority: true,
    mask: "(###) ###-####",
  },
  {
    code: "UY",
    flag: "🇺🇾",
    name: "Uruguay",
    phone: "598",
    mask: "#-###-##-##",
  },
  {
    code: "UZ",
    flag: "🇺🇿",
    name: "Uzbekistan",
    phone: "998",
    mask: "##-###-####",
  },
  {
    code: "VA",
    flag: "🇻🇦",
    name: "Vatican City",
    phone: "379",
    mask: "06 698#####",
  },
  {
    code: "VC",
    flag: "🇻🇨",
    name: "Saint Vincent and The Grenadines",
    phone: "1-784",
    mask: "(784) ###-####",
  },
  {
    code: "VE",
    flag: "🇻🇪",
    name: "Venezuela",
    phone: "58",
    mask: "(###) ###-####",
  },
  {
    code: "VG",
    flag: "🇻🇬",
    name: "Virgin Islands, British",
    phone: "1-284",
    mask: "(284) ###-####",
  },
  {
    code: "VI",
    flag: "🇻🇮",
    name: "Virgin Islands, U.S.",
    phone: "1-340",
    mask: "(340) ###-####",
  },
  {
    code: "VN",
    flag: "🇻🇳",
    name: "Vietnam",
    phone: "84",
    mask: ["##-####-###", "(###) ####-###"],
  },
  {
    code: "VU",
    flag: "🇻🇺",
    name: "Vanuatu",
    phone: "678",
    mask: ["#####", "##-#####"],
  },
  {
    code: "WF",
    flag: "🇼🇫",
    name: "Wallis and Futuna",
    phone: "681",
    mask: "##-####",
  },
  { code: "WS", flag: "🇼🇸", name: "Samoa", phone: "685", mask: "##-####" },
  {
    code: "YE",
    flag: "🇾🇪",
    name: "Yemen",
    phone: "967",
    mask: ["#-###-###", "##-###-###", "###-###-###"],
  },
  {
    code: "ZA",
    flag: "🇿🇦",
    name: "South Africa",
    phone: "27",
    mask: "##-###-####",
  },
  { code: "ZM", flag: "🇿🇲", name: "Zambia", phone: "260", mask: "##-###-####" },
  { code: "ZW", flag: "🇿🇼", name: "Zimbabwe", phone: "263", mask: "#-######" },
]


const CountriesPriorityShip: CountryFlagItemType[] = CountryFlagData.filter(
  (item) => item.priority
).sort((a, b) => (a.name < b.name ? -1 : 1))

const CountriesNonPriorityShip: CountryFlagItemType[] = CountryFlagData.filter(
  (item) => !item.priority
).sort((a, b) => (a.name < b.name ? -1 : 1))

const CountriesWithPriorityOnTop: CountryFlagItemType[] = [
  ...CountriesPriorityShip,
  ...CountriesNonPriorityShip,
]

*/
const CountriesWithPriorityOnTop = CountryFlagData

/**
 * @description - Get a flag from a country code
 * @param {string} countryCode - The country code
 * @return a flag string
 */
const flagFromCode = (code: String) => {
  const results = CountryFlagData.filter((item) => item.code === code)
  if (results.length === 0) return null
  return results[0].flag
}

/**
 * @description - Get a phone code from a country code
 * @param {string} countryCode - The phone code
 * @return a phone string
 */
const phoneFromCode = (code: String) => {
  const results = CountryFlagData.filter((item) => item.code === code)
  if (results.length === 0) return null
  return results[0].phone
}

/**
 * @description - Returns list of countries sorted by name
 * @return an array of CountryFlagItemType
 */
const CountryFlagDataByName = CountryFlagData.sort((a, b) =>
  a.name < b.name ? -1 : a.name > b.name ? 1 : 0
)

export {
  CountriesWithPriorityOnTop,
  // CountriesPriorityShip,
  // CountriesNonPriorityShip,
  CountryFlagData,
  CountryFlagDataByName,
  flagFromCode,
  phoneFromCode,
}
